.section {
  --image-width: 320px;
  --image-height: 480px;
  --image-right-position: 0;
  --grid-outer-gutter: -15px;
  --section-background-mobile: var(--white);
  --section-background-tablet: var(--white);
  --section-background-desktop: var(--white);

  @media (min-width: 640px) {
    --image-width: 420px;
    --image-height: 620px;
    --image-right-position: -50px;
    --grid-outer-gutter: -30px;
  }

  @media (min-width: 960px) {
    --image-right-position: 0;
    --grid-outer-gutter: -40px;
  }
}

.section {
  position: relative;
  background: var(--section-background-mobile);

  @media (min-width: 640px) {
    background: var(--section-background-tablet);
  }

  @media (min-width: 960px) {
    background: var(--section-background-desktop);
  }

  &:first-child {
    border-top: none;
  }

  &--gradient {
    --gradient-deg: 0deg;
    --gradient-begin-color: var(--z-white);
    --gradient-begin-percent: 0%;
    --gradient-last-color: transparent;
    --gradient-last-percent: 50%;

    @media (min-width: 640px) {
      --gradient-deg: 90deg;
      --gradient-begin-color: var(--z-white);
      --gradient-begin-percent: 60%;
      --gradient-last-color: var(--z-tide);
      --gradient-last-percent: 100%;
    }

    @media (min-width: 960px) {
      --gradient-deg: 90deg;
      --gradient-begin-color: var(--z-white);
      --gradient-begin-percent: 30%;
      --gradient-last-color: var(--z-tide);
      --gradient-last-percent: 100%;
    }

    .uk-container {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        z-index: 1;

        left: 0;
        top: calc(-1px * var(--gutter));

        width: 100%;
        height: calc(100% + 2px * var(--gutter));

        background: linear-gradient(var(--gradient-deg), var(--gradient-begin-color) var(--gradient-begin-percent), var(--gradient-last-color) var(--gradient-last-percent));

        @media (min-width: 640px) {
          left: auto;
          bottom: auto;
          top: calc(-1px * var(--gutter));
          right: calc(var(--image-width) + var(--image-right-position));

          height: calc(100% + var(--gutter) * 2px);
          width: 100vw;
        }

        @media (min-width: 960px) {
          left: auto;
          bottom: auto;
          top: calc(-1px * var(--gutter));
          right: var(--image-width);

          height: calc(100% + var(--gutter) * 2px);
          width: 100vw;
        }
      }
    }

    &.section--image-reverse {

      @media (min-width: 640px) {
        --gradient-deg: -90deg;
      }

      @media (min-width: 960px) {
        --gradient-deg: -90deg;
      }

      .uk-container {
        &::before {
          @media (min-width: 640px) {
            right: auto;
            left: calc(var(--image-width) + var(--image-right-position));
          }

          @media (min-width: 960px) {
            right: auto;
            left: var(--image-width);
          }
        }
      }
    }
  }

  &--neurocoaching {
    --section-background-mobile: #b6b2b0;
    --section-background-tablet: #b6b2b0;
    --section-background-desktop: #b6b2b0;

    @media (min-width: 640px) {
      --gradient-last-color: #b5b2ae;
    }

    background-color: #b6b2b0;
  }

  &--butterfly {
    --section-background-mobile: #aba5a1;
    --section-background-tablet: #b4b3b1;
    --section-background-desktop: #b4b3b1;

    @media (min-width: 640px) {
      --gradient-last-color: #aba5a1;
    }
  }

  &--about {
    --section-background-mobile: #b6b2b0;
    --section-background-tablet: #b6b2b0;
    --section-background-desktop: #b7b2ac;

    @media (min-width: 640px) {
      --gradient-last-color: transparent;
    }
  }

  &--target {
    --section-background-mobile: #b6b2b0;
    --section-background-tablet: #b6b2b0;
    --section-background-desktop: #b5b1b2;

    @media (min-width: 640px) {
      --gradient-last-color: #afaba9;
    }
  }

  &--light {
    background-color: var(--z-mercury);
  }

  &--image {
    overflow: hidden;
    border-top: 2px solid var(--z-cloud);
    border-bottom: 2px solid var(--z-cloud);
  }

  &--frame {
    overflow: hidden;
  }

  &__container {
    position: relative;
    z-index: 2;

    .section--gradient & {
      padding-top: 60px;

      @media (min-width: 640px) {
        padding-top: 0;
      }
    }
  }

  &__header,
  &__body {
    position: relative;
    z-index: 1;
  }

  &__image {
    height: 53vh;
    position: relative;
    margin-right: var(--grid-outer-gutter);
    max-height: calc(var(--image-height) - 2px * var(--gutter) - 20px);

    @media (min-width: 640px) {
      padding-bottom: calc(var(--image-height) - var(--gutter) * 2px);
      max-height: none;
      height: auto;
    }
  }

  &__img {
    position: absolute;
    width: var(--image-width);
    min-height: var(--image-height);
    top: calc(-1px * var(--gutter));
    right: var(--image-right-position);

    &--frame {
      --image-width: 531;
      --image-height: 561;

      position: absolute;
      top: 50%;
      left: 80%;
      right: auto;
      width: calc(var(--image-width) / var(--fz) * var(--fz-em));
      height: calc(var(--image-height) / var(--fz) * var(--fz-em));
      transform: translate3d(-50%, -50%, 0);

      &::before {
        display: none;
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .section--image-reverse & {

      @media (min-width: 640px) {
        right: auto;
        left: var(--image-right-position);
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      object-fit: cover;
    }
  }

  &__header+&__header,
  &__header+&__body,
  &__body+&__header {
    margin-top: calc(20 / var(--fz) * var(--fz-em));

    @media (min-width: 960px) {
      margin-top: calc(40 / var(--fz) * var(--fz-em));
    }
  }


  &__title+&__subtitle {
    margin-top: calc(16 / var(--fz) * var(--fz-em));

    @media (min-width: 960px) {
      margin-top: calc(30 / var(--fz) * var(--fz-em));
    }
  }

  &__subtitle {
    color: var(--text-color);

    p {
      line-height: 1.5;
    }

    .section--image & {
      --fz: 14;

      font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
      text-shadow: calc(1 / var(--fz) * var(--fz-em)) calc(1 / var(--fz) * var(--fz-em)) calc(1 / var(--fz) * var(--fz-em)) var(--header-color-shadow);

      @media (min-width: 960px) {
        font-size: inherit;
      }
    }
  }
}